import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout className="contactpage">
    <section className="contact">
      <SEO title="Contact" />
      <h1>Get in touch</h1>

      <form action="https://formspree.io/f/mgerddbj" method="POST">
      <label>Name:</label><input  class="field" type="text" name="Name" placeholder="Name"></input>
      <label>Email:</label><input class="field" type="email" name="_replyto" placeholder="Email"></input>
      <label>Tel:</label><input class="field" type="text" name="Tel" placeholder="Tel"></input>
      <label>Your message:</label><textarea class="text-field" name="message" placeholder="Message"></textarea>
      <button class="submit" type="submit">Send</button>
      </form>
      
    </section>
  </Layout>
)

export default ContactPage
